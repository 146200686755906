import React from 'react';

interface IPageTheme {
  themeBlok: [
    {
      colorPrimaryPreset: string;
      colorPrimary: string;
      colorBackgroundPreset: string;
      colorBackground: string;
      variant: string;
    }
  ];
}

const PageTheme: React.FC<IPageTheme> = ({ themeBlok }) => {
  if (!themeBlok || themeBlok.length !== 1) return;

  const [{ colorPrimaryPreset, colorPrimary, colorBackgroundPreset, colorBackground, variant }] =
    themeBlok;
  return (
    <style
      dangerouslySetInnerHTML={{
        __html: `:root { 
            --page-theme-primary: ${colorPrimary || colorPrimaryPreset};
            --page-theme-background: ${
              colorBackground || colorBackgroundPreset || `var(--${variant})`
            };
          }`
      }}
    />
  );
};

export default PageTheme;
