import clsx from 'clsx';
import FocusTrap from 'focus-trap-react';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import withHydrationOnDemand from 'react-hydration-on-demand';
import { TypefaceLicences } from 'types/common';

import { AddTypefaceToCart } from '@/components';
import Button from '@/components/global/Button';
import Icon from '@/components/global/Icon';
import Link from '@/components/global/Link';
import { SuccessToast } from '@/components/global/Toast';
import { useRegions } from '@/components/providers/RegionsProvider';
import { useTypefaceAddToCart } from '@/components/providers/TypefaceProvider';
import { useUser } from '@/lib/hooks/useUser';
import { usePaths } from '@/lib/paths';
import { ProductDetailsFragment, useProductBySlugQuery } from '@/saleor/api';

import styles from './NavbarFloating.module.scss';

const focusTrapWatcher = ([container]) =>
  new Promise<void>((resolve) => setTimeout(() => resolve(), 400));

const loopThroughParents = (el, check) => {
  check(el);
  if (el.parentElement) loopThroughParents(el.parentElement, check);
};

const NavLink = ({ link, current, closeMenu }) =>
  link === current ? (
    <li key={link.text} onClick={closeMenu}>
      <span className={clsx(styles.currentLink, 'u-h5')}>{link.text}</span>
    </li>
  ) : (
    <li key={link.text}>
      <Link href={link.href} label={link.text} className={clsx(styles.link, 'u-h5')} />
    </li>
  );

type NavbarLinkType = {
  href: string;
  text: string;
};

interface Props {
  links: NavbarLinkType[];
  className?: string;
  product: ProductDetailsFragment;
  productLicences?: TypefaceLicences;
}

const AddTypefaceToCartWithHydrationOnDemand = withHydrationOnDemand({
  on: ['visible']
})(AddTypefaceToCart);

const NavbarFloating: React.FC<Props> = ({
  className = '',
  links = [],
  product,
  productLicences
}) => {
  const paths = usePaths();
  const { query } = useRegions();
  const { authenticated } = useUser();
  const [hasMounted, setHasMounted] = useState(false);

  // Get product variant data
  const { loading, error, data } = useProductBySlugQuery({
    variables: {
      slug: product.slug,
      locale: query.locale,
      channel: 'uk-store'
    }
  });

  const {
    setIsOpen: setConfiguratorVisible,
    resetState,
    state: typefaceState
  } = useTypefaceAddToCart();
  const { isOpen: configuratorVisible, isAddedToCart } = typefaceState;
  const [navMenuOpen, setNavMenuOpen] = useState(false);

  const closeMenu = () => setNavMenuOpen(false);
  const openConfigurator = () => setConfiguratorVisible(true);
  const closeConfigurator = () => setConfiguratorVisible(false);

  const router = useRouter();
  const typefaceRoot = router.asPath.split('/').slice(0, 3).join('/');
  const overviewSection = { href: typefaceRoot, text: 'Overview' };

  const getSection = () => {
    const path = router.pathname.split('[slug]')[1].split('/')[1];
    let href = (path ? path : 'Overview').replace('/', '');
    const current = links.find((link) => link.href.includes(href));
    if (current) return current;
    return overviewSection;
  };
  const typefaceSection = getSection();

  const focusTrapOptions = {
    checkCanFocusTrap: focusTrapWatcher,
    allowOutsideClick: true
  };

  useEffect(() => {
    if (!navMenuOpen) return;
    const clickListen = (ev) => {
      if (!navMenuOpen) return;

      let isNavMenuClick = false;
      loopThroughParents(ev.target, (el) => {
        if (el.className === styles.container) isNavMenuClick = true;
      });
      if (!isNavMenuClick) setNavMenuOpen(false);
    };

    window.addEventListener('click', clickListen);
    document.addEventListener('touchstart', clickListen);
    return () => {
      window.removeEventListener('click', clickListen);
      document.removeEventListener('touchstart', clickListen);
    };
  }, [navMenuOpen]);

  const successToastAction = (checkout = false) => {
    closeConfigurator();
    resetState();
    document.body.classList.remove('ReactModal__Body--open');

    if (checkout) {
      router.push(paths.cart.$url());
    }
  };

  // Fix hydration error 2-pass rendering
  useEffect(() => {
    setHasMounted(true);
  }, []);

  if (!hasMounted) {
    return null;
  }

  return (
    <>
      {isAddedToCart && <SuccessToast successToastAction={successToastAction} />}
      {data?.product && (
        <AddTypefaceToCartWithHydrationOnDemand
          product={data.product}
          setClosed={closeConfigurator}
          productLicences={productLicences}
          isOpen={configuratorVisible}
        />
      )}
      <nav className={clsx(styles.container, className)}>
        <div className={styles.inner}>
          <FocusTrap active={navMenuOpen} focusTrapOptions={focusTrapOptions}>
            <div className={clsx(styles.navMenu, navMenuOpen && styles.navMenu__open)}>
              <Button
                variant="ghost"
                type="button"
                size="l"
                className={styles.close}
                callBack={closeMenu}
              >
                <Icon iconName="close" width="24" height="24" />
              </Button>

              <Button
                className={clsx(styles.controls__button, styles.menuBuy)}
                type="button"
                size="l"
                callBack={() => openConfigurator()}
                dynamicSize={false}
              >
                Buy {product.name}
              </Button>
              <ul>
                <NavLink link={overviewSection} current={typefaceSection} closeMenu={closeMenu} />
                {links.map((link) => (
                  <NavLink
                    key={link.text}
                    link={link}
                    current={typefaceSection}
                    closeMenu={closeMenu}
                  />
                ))}
                <li>
                  <NextLink
                    href={`/account${!authenticated ? '/login' : ''}`}
                    {...(authenticated && {
                      as: `/account/trials?font=${product.id}`
                    })}
                  >
                    <a className={clsx(styles.link, 'u-h5')}>Trials</a>
                  </NextLink>
                </li>
              </ul>
            </div>
          </FocusTrap>
          <div className={clsx(styles.controls, navMenuOpen && styles.controls__open)}>
            <Button
              className={clsx(styles.controls__button, styles.navigate)}
              type="button"
              variant="secondary"
              size="l"
              dynamicSize={false}
              callBack={() => setNavMenuOpen(true)}
            >
              {typefaceSection?.text}
            </Button>
            <Button
              className={clsx(styles.controls__button, styles.buy)}
              type="button"
              size="l"
              dynamicSize={false}
              callBack={() => openConfigurator()}
            >
              Buy {product.name}
            </Button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default NavbarFloating;
